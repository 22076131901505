<template>
    <div>
        <v-container fluid class="grid-list-xl pt-0 mt-12">
            <v-row class="align-center justify-center">
                <v-col cols="12" md="9" class="px-0 mt-3">
                    <v-card flat tile class="pt-5">
                        <span class="ml-2">
                            <v-icon color="grey" class="pointer" onclick="javascript:history.back(-1);">mdi-arrow-left</v-icon>
                        </span>
                        <h3 class="text-center mb-5">{{ $t('ga.bindTitle') }}</h3>
                        <v-row>
                            <v-col md="3"></v-col>
                            <v-col md="6" sm="12">
                                <v-alert dense tile dark color="grey white--text" icon="mdi-information-outline" class="fs-14">
                                    {{ $t('ga.gaAlert') }}
                                </v-alert>
                                <!-- 第一步 -->
                                <v-col cols="12" class="fs-18 fw-bold">
                                    <v-icon>mdi-numeric-1-box-outline</v-icon>
                                    <span>{{ $t('ga.downloadGA') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <span>{{ $t('ga.downloadGAMsg') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-btn block tile color="light-blue" class="text-none white--text" href="https://itunes.apple.com/cn/app/google-authenticator/id388497605" target="_blank">
                                                <v-icon left>mdi-apple</v-icon>
                                                <span>App Store</span>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-btn block tile color="light-blue" class="text-none white--text" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                                                <v-icon left>mdi-google-play</v-icon>
                                                <span>Google Play</span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </v-col>
                                <!-- 第二步 -->
                                <v-col cols="12" class="fs-18 fw-bold">
                                    <v-icon>mdi-numeric-2-box-outline</v-icon>
                                    <span>{{ $t('ga.addAndBackUpMsg') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <span>{{ $t('ga.qrcodeMsg') }}</span><br/>
                                    <span>{{ $t('ga.backupMsg') }}</span>
                                </v-col>
                                <v-col cols="12">
                                    <v-row v-if="!user.openAuth">
                                        <v-col cols="12">
                                            <span class="fs-20 fw-bold pointer" @click="copyToClipboard(userInfo.authSecret)">{{ userInfo.authSecret }}</span>
                                        </v-col>
                                        <v-col cols="auto">
                                                <canvas id="canvas" width="180px" height="180px;"></canvas>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="user.openAuth">
                                        <v-col cols="12">
                                            <span class="fs-18 fw-bold">{{ $t('ga.enabledMsg') }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-divider></v-divider>
                                </v-col>
                                <!-- 第三步 -->
                                <div v-if="!user.openAuth">
                                    <v-col cols="12" class="fs-18 fw-bold">
                                        <v-icon>mdi-numeric-3-box-outline</v-icon>
                                        <span>{{ $t('ga.verificationCodeMsg') }}</span>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field v-model="otp" single-line outlined counter="6" @keyup.enter="submit"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-btn block tile color="light-blue" class="text-none white--text" @click="submit">
                                            <span>{{ $t('common.submit') }}</span>
                                        </v-btn>
                                    </v-col>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style>

</style>
<script>
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import QRCode from 'qrcode';
    import Bus from '@/components/bus/Bus';
    export default {
        data(){
            return {
                // 用户秘钥信息
                userInfo: {},
                // 验证码
                otp: null
            }
        },
        created(){

        },
        mounted(){
            if (!this.user.token) {
                this.$router.push({ name: "signIn" });
            }
            this.getSecretKey();
        },
        components:{ },
        computed: {
            ...mapGetters(['user'])
        },
        watch:{
            '$route'(to, from){
                if(from.path != '/user/ga/enable'){
                    this.getSecretKey();
                }
            }
        },
        methods: {
            //生成二维码
            generateQRCode(){
                if (this.userInfo.qrcode) {
                    // otpauth://totp/ioBots:test.iobots@iob.vc?secret=ABCDEFGHIJKLMNOP&issuer=ioBots
                    let url = this.userInfo.qrcode;
                    var canvas = document.getElementById('canvas');
                    QRCode.toCanvas(canvas, url, {margin: 1}, function (error) {});
                }
            },
            // 复制到剪切板
            copyToClipboard(message){
                this.$copyText(message).then(res => {
                  // 复制成功
                  this.$store.dispatch('snackbarMessageHandler', this.$t('common.copied'));
                }).catch(err => {
                  // 复制失败
                  this.$store.dispatch('snackbarMessageHandler', this.$t('common.copyFailed'));
                })
            },
            // 查询秘钥
            getSecretKey(){
                let api = config.baseUrl + '/ga/private/secret';
                this.$http.get(api).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        // 生成秘钥成功
                        this.userInfo = data.result;
                        this.generateQRCode();
                    } else {
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            },
            // 提交请求
            submit(){
                let api = config.baseUrl + '/ga/private/enable';
                let param = {otp: this.otp};
                this.$http.post(api, param).then(response => {
                    let data = response.data;
                    if (data.code == 200) {
                        // 开启成功
                        Bus.$emit('emitGetMe', {});
                        this.$router.push({name: 'account'});
                    } else {
                        this.otp = null;
                        this.$store.dispatch('snackbarMessageHandler', data.message);
                    }
                }, error => {
                    this.$store.dispatch('snackbarMessageHandler', this.$t('common.oops'));
                });
            }
        }
    }
</script>
